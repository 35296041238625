import React, { useState } from "react";
import { observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import { Spinner, Button, DatePicker } from "..";
import classes from "./EditShippingDatesModal.module.scss";
import { differenceInDays, eachWeekendOfInterval, lastDayOfMonth, parseISO } from "date-fns";

function getShippingWindowDateRanges() {
  const thisYear = new Date().getFullYear();
  const summerMinDate = parseISO(`${thisYear}-03-01`);
  const summerMaxDate = parseISO(`${thisYear + 1}-06-30`);
  const fallMinDate = parseISO(`${thisYear}-07-01`);
  const fallMaxDate = parseISO(`${thisYear + 1}-10-31`);
  const winterMinDate = parseISO(`${thisYear}-11-01`);
  const winterMaxDate = lastDayOfMonth(parseISO(`${thisYear + 1}-02-01`));
  return { thisYear, winterMinDate, winterMaxDate, summerMinDate, summerMaxDate, fallMinDate, fallMaxDate };
}

function EditShippingDatesModal({ teacherInfo, close }) {
  const { shippingDates } = teacherInfo || {};

  const dateChangeDisabled = date => date && differenceInDays(date, new Date()) <= 14;

  const [winter, setWinter] = useState(shippingDates?.winter && parseISO(shippingDates?.winter));
  const [summer, setSummer] = useState(shippingDates?.summer && parseISO(shippingDates?.summer));
  const [fall, setFall] = useState(shippingDates?.fall && parseISO(shippingDates?.fall));

  const [updating, setUpdating] = useState(false);

  const updateEnabled = winter && summer && fall;

  const updateShippingDates = async () => {
    setUpdating(true);
    await UserStore.updateShippingDatesForTeacher({ shippingDates: { winter, summer, fall } });
    setUpdating(false);
    close();
  };

  const {
    // thisYear,
    winterMinDate,
    winterMaxDate,
    summerMinDate,
    summerMaxDate,
    fallMinDate,
    fallMaxDate
  } = getShippingWindowDateRanges();
  // const thisYearAbbrev = "'" + String(thisYear).slice(2, 4);
  // const nextYearAbbrev = "'" + String(thisYear + 1).slice(2, 4);

  const cancelButtonStyles = updating ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateEnabled
    ? updating
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = updating ? <Spinner /> : "Update";

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.title}>Manage Shipping Dates</div>
        <div className={`${classes.inputTitle} ${classes.first}`}>
          {/* from about Nov 1st to Dec 31st these shipping dates need to be hard coded.  */}
          Spring/Summer’25 Window
          {/* Spring/Summer {thisYearAbbrev} window */}
          <span className={classes.inputSubTitle}> (Mar 1 - Jun 30)</span>
        </div>
        <DatePicker
          placeholder="Select date..."
          value={summer}
          minDate={summerMinDate}
          maxDate={summerMaxDate}
          excludeDates={eachWeekendOfInterval({ start: summerMinDate, end: summerMaxDate })}
          onChange={setSummer}
          disabled={dateChangeDisabled(summer)}
        />
        <div className={classes.inputTitle}>
          {/* from about Nov 1st to Dec 31st these shipping dates need to be hard coded.  */}
          Fall ’25 window
          {/* Fall {thisYearAbbrev} window */}
          <span className={classes.inputSubTitle}> (Jul 1 - Sep 30)</span>
        </div>
        <DatePicker
          placeholder="Select date..."
          value={fall}
          minDate={fallMinDate}
          maxDate={fallMaxDate}
          excludeDates={eachWeekendOfInterval({ start: fallMinDate, end: fallMaxDate })}
          onChange={setFall}
          disabled={dateChangeDisabled(fall)}
        />
        <div className={classes.inputTitle}>
          {/* from about Nov 1st to Dec 31st these shipping dates need to be hard coded.  */}
          Winter ’25-26 window
          {/* Winter {thisYearAbbrev} - {nextYearAbbrev} window */}
          <span className={classes.inputSubTitle}> (Nov 1 - Feb 30)</span>
        </div>
        <DatePicker
          placeholder="Select date..."
          value={winter}
          minDate={winterMinDate}
          maxDate={winterMaxDate}
          excludeDates={eachWeekendOfInterval({ start: winterMinDate, end: winterMaxDate })}
          onChange={setWinter}
          disabled={dateChangeDisabled(winter)}
        />
        <div className={classes.outOfEditRange}>
          Shipping dates can only be edited up until 14 days before the selected date. If you are within the 14 day
          window and you need to change your shipping date, reach out to us at{" "}
          <a href="mailto:office@letsplaymusicsite.com">office@letsplaymusicsite.com</a> and we can help you with that.
        </div>
      </div>
      <div className={classes.bottomButtons}>
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className={classes.leftButtons}>
          <Button type="primary" action={updateShippingDates} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditShippingDatesModal);
